import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?p=202

const EventHledaniPrince: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="Hledání prince"
        link="/event_hledani_prince"
        author="Algy"
        date="18.03.2014"
        time="13:24"
        category="Eventy"
      />
      <h4 style={{ textAlign: 'justify' }}><strong>EVENT Hledání prince</strong><p></p>
        <p><em>Zdravím lide Giranský,<br />
          Váš král Giranského hradu potřebuje vaši pomoc a všechny Vás vyzývá : Zanechte farmeni/PvP a pojďte přiložit ruku k dílu!&nbsp;Jeho dcera Katarina,se má provdat za prince,který ale utekl někde do Giranského města. Strážci města si neví rady,jak ho&nbsp;dopadnout. Buďte opatrní a zároveň velice rychlí. Král nabízi odměnu tomu,kdo prince dopadne.Jediné co na prince platí je,&nbsp;uplácení zlatými adeny,aby šel s Vámi. Pokud uplatu sebere a zmizi,je potřeba to nevzdávat a hledat znova a pokusit se opět ho uplatit.</em></p></h4>
      <div>
        <div style={{ textAlign: 'justify' }}>
          <div id="msg_1567">
            <strong>PRAVIDLA EVENTU:</strong><br />
            <strong>Obecně</strong><br />
            1/ V průběhu všech eventů (vyjma PvP eventů) jsou zabíjení hráčů a útoky na GM postavu zakázány (není-li to výslovně povoleno). Porušení tohoto pravidla bude trestáno podle závažnosti prohřešku.<p></p>
            <p>2/ Uvedená pravidla může GM kdykoliv změnit, přitom však musí dbát na to, aby taková změna nezvýhodnila žádnou z postav, které se eventu účastní.</p>
            <p><strong>Hledání prince</strong><br />
              -GM má 10 daných míst kde se bude schovávat.<br />
              -první hráč,který nalezne a vyhodí před GM&nbsp; &nbsp;<strong>1 ADENU</strong>,kterou GM zvedne dostáva bod<br />
              -GM po zvednutí adeny od hráče zmizí a přemístí se na další místo<br />
              -Hráč může použít jakékoliv prostředky k hledání GM,které nejsou v rozporu s pravidly na serveru/eventu<br />
              -Při doběhu a vyhození adeny více hráčů ve stejný čas,se připisují body všem.<br />
              -Hráč,který bude kazit event nebo hru ostatním hráčům,bude potrestán.</p></div>
        </div>
      </div>
      <p>&nbsp;</p>
      <div></div>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventHledaniPrince

export { Head } from '../components/defaultHtmlHead'